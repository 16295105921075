import { getTicketAmount } from './global-functions'
import { productIsParkTicket } from './global-variables'

export const setFieldsDependingOnTicketAmount = function () {
  const addToCartButton = document.querySelector('[data-js-add-to-cart]')

  if (addToCartButton) {
    if (getTicketAmount() > 0) {
      addToCartButton.removeAttribute('disabled')
    } else {
      addToCartButton.setAttribute('disabled', 'disabled')
    }
  }
}

if (productIsParkTicket) {
  setFieldsDependingOnTicketAmount()
}
