import { currency, locale } from './global-variables'

export const calculateTotalPrice = function () {
  const amountInputs = Array.from(document.querySelectorAll('[data-js-amount-input]'))
  const deliveryPriceSelector = document.querySelector('[data-js-delivery-price]:checked')
  let deliveryPrice = 0

  if (deliveryPriceSelector) {
    deliveryPrice = deliveryPriceSelector.dataset.jsDeliveryPrice
  }

  let totalPrice = 0
  for (const key in amountInputs) {
    const input = amountInputs[key].querySelector('input')
    const amount = input.value
    let price = input.dataset.jsPrice / 100

    if (deliveryPrice > 0) {
      price += deliveryPrice / 100
    }

    totalPrice += (price * amount) * 100
  }

  const totalPriceElement = document.querySelector('[data-js-total-price]')

  if (totalPriceElement) {
    totalPriceElement.innerHTML = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(totalPrice)
  }
}
