const selectFromAdressBookDropdowns = document.querySelectorAll('[data-js-select-from-address-book]')
const checkoutShippingAddress = document.querySelector('#sylius-shipping-address')
const checkoutBillingAddress = document.querySelector('#sylius-billing-address')
const addressBook = function addressBook (addressSelector, addressType) {
  const element = addressSelector
  const formName = element.closest('form').name
  const addressBookDropdowns = element.querySelectorAll('[data-js-select-from-address-book]')

  addressBookDropdowns.forEach((select) => {
    select.addEventListener('click', (name) => {
      element.querySelectorAll('input:not([type="radio"]):not([type="checkbox"]), select').forEach((input) => {
        input.value = ''
      })

      const data = {};

      [].forEach.call(select.attributes, function (attr) {
        if (/^data-/.test(attr.name)) {
          const camelCaseName = attr.name.substr(5).replace(/-(.)/g, function ($0, $1) {
            return $1.toUpperCase()
          })
          data[camelCaseName] = attr.value
        }
      })

      Object.keys(data).forEach(function (property) {
        const value = data[property]

        const field = document.querySelector('#' + formName + '_' + addressType + '_' + property)

        if (field !== null) {
          field.value = value
        }
      })
    })
  })
}

if (selectFromAdressBookDropdowns.length) {
  if (checkoutShippingAddress) {
    addressBook(checkoutShippingAddress, 'shippingAddress')
  }

  if (checkoutBillingAddress) {
    addressBook(checkoutBillingAddress, 'billingAddress')
  }
}
