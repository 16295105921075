import { productIsFantissima, productIsParkTicket } from './global-variables'
import { setAmountButtonStates, triggerAmountInputs } from './global-functions'
import { calculateTotalPrice } from './calculate-total-price'
import { setFieldsDependingOnTicketAmount } from './parkticket-section-visibility'
import { setFieldsDependingOnFantissimaTicketAmount } from './fantissima-section-visibility'

export const initAmountInputs = function () {
  const amountInputs = Array.from(document.querySelectorAll('[data-js-amount-input]'))

  amountInputs.forEach((amountInput) => {
    const decreaseButton = amountInput.querySelectorAll('[data-js-decrease-amount]').item(0)
    const increaseButton = amountInput.querySelectorAll('[data-js-increase-amount]').item(0)
    const input = amountInput.getElementsByTagName('input')[0]
    let max = Number.parseInt(input.max, 10)
    const min = Number.parseInt(input.min, 10)

    if (decreaseButton && increaseButton) {
      setAmountButtonStates(input, decreaseButton, increaseButton, max, min)

      decreaseButton.addEventListener('click', () => {
        const amount = Number.parseInt(input.value)
        max = Number.parseInt(input.max, 10)

        if (!isNaN(min) && amount > min) {
          input.value = amount - 1
        }

        handleChange(input, decreaseButton, increaseButton, max, min)
      })

      increaseButton.addEventListener('click', () => {
        const amount = Number.parseInt(input.value)
        max = Number.parseInt(input.max, 10)

        if (!isNaN(max) && amount < max) {
          input.value = amount + 1
        }

        handleChange(input, decreaseButton, increaseButton, max, min)
      })
    }
  })

  function handleChange (input, decreaseButton, increaseButton, max, min) {
    triggerAmountInputs()

    if (productIsParkTicket) {
      setFieldsDependingOnTicketAmount()
    }

    if (productIsFantissima) {
      setFieldsDependingOnFantissimaTicketAmount()
    }

    setAmountButtonStates(input, decreaseButton, increaseButton, max, min)

    calculateTotalPrice(input)
  }
}

export const resetAmountInputs = function () {
  const amountCart = document.querySelector('[data-js-amount-cart]')

  if (amountCart) {
    const ticketAmountInputs = Array.from(amountCart.querySelectorAll('[data-js-amount-input] input'))

    ticketAmountInputs.forEach((ticketAmountInput) => {
      const decreaseButton = ticketAmountInput.parentNode.querySelector('[data-js-decrease-amount]')

      ticketAmountInput.value = 0
      decreaseButton.setAttribute('disabled', 'disabled')
      decreaseButton.dataset.minReached = 'true'
    })

    if (productIsFantissima) {
      setFieldsDependingOnFantissimaTicketAmount()
    }

    const event = new Event('resetAmountInputs')
    document.dispatchEvent(event)
  }
}
