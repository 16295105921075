export const setAmountButtonStates = function (input, decreaseButton, increaseButton, max, min) {
  const amount = Number.parseInt(input.value)
  decreaseButton.disabled = false
  increaseButton.disabled = false
  decreaseButton.dataset.minReached = 'false'
  increaseButton.dataset.maxReached = 'false'

  if (!isNaN(min) && amount === min) {
    decreaseButton.disabled = true
    decreaseButton.dataset.minReached = 'true'
  }
  if (!isNaN(max) && amount === max) {
    increaseButton.disabled = true
    increaseButton.dataset.maxReached = 'true'
  }
}

export const triggerAmountInputs = function () {
  const inputFields = document.querySelectorAll('[data-js-amount-input] input')

  inputFields.forEach((inputField) => {
    const changeEvent = new Event('change')
    inputField.dispatchEvent(changeEvent)
  })
}

export const getTicketAmount = function () {
  const amountCart = document.querySelector('[data-js-amount-cart]')
  if (amountCart) {
    const ticketAmountInputs = Array.from(amountCart.querySelectorAll('[data-js-amount-input] input'))
    let ticketAmountInputSum = 0

    for (let i = 0; i < ticketAmountInputs.length; i++) {
      ticketAmountInputSum += parseInt(ticketAmountInputs[i].value)
    }

    return ticketAmountInputSum
  }
}
